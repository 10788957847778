exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-complaint-handling-process-tsx": () => import("./../../../src/pages/complaint-handling-process.tsx" /* webpackChunkName: "component---src-pages-complaint-handling-process-tsx" */),
  "component---src-pages-fees-schedule-tsx": () => import("./../../../src/pages/fees-schedule.tsx" /* webpackChunkName: "component---src-pages-fees-schedule-tsx" */),
  "component---src-pages-hotshop-promo-tsx": () => import("./../../../src/pages/hotshop-promo.tsx" /* webpackChunkName: "component---src-pages-hotshop-promo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-account-relationship-disclosure-tsx": () => import("./../../../src/pages/legal/account-relationship-disclosure.tsx" /* webpackChunkName: "component---src-pages-legal-account-relationship-disclosure-tsx" */),
  "component---src-pages-legal-best-execution-and-fair-pricing-disclosure-tsx": () => import("./../../../src/pages/legal/best-execution-and-fair-pricing-disclosure.tsx" /* webpackChunkName: "component---src-pages-legal-best-execution-and-fair-pricing-disclosure-tsx" */),
  "component---src-pages-legal-complaint-handling-process-tsx": () => import("./../../../src/pages/legal/complaint-handling-process.tsx" /* webpackChunkName: "component---src-pages-legal-complaint-handling-process-tsx" */),
  "component---src-pages-legal-conflict-of-interest-statement-tsx": () => import("./../../../src/pages/legal/conflict-of-interest-statement.tsx" /* webpackChunkName: "component---src-pages-legal-conflict-of-interest-statement-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-terms-and-conditions-tsx": () => import("./../../../src/pages/legal/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-promo-hotshop-promo-tsx": () => import("./../../../src/pages/promo/hotshop-promo.tsx" /* webpackChunkName: "component---src-pages-promo-hotshop-promo-tsx" */),
  "component---src-pages-promo-sign-up-promo-tsx": () => import("./../../../src/pages/promo/sign-up-promo.tsx" /* webpackChunkName: "component---src-pages-promo-sign-up-promo-tsx" */),
  "component---src-pages-signup-promo-tsx": () => import("./../../../src/pages/signup-promo.tsx" /* webpackChunkName: "component---src-pages-signup-promo-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

